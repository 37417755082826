import {
  ref,
  get,
  //child,
  push,
  /*
  update,
  remove,*/
  DataSnapshot
} from "firebase/database"
import Utility from "@/helpers/vuex"
import db from "../Conn"
const TabellaRef = ref(db, "articoli")
type TypeElemento = {
  Data: string
  Titolo: string
  Fonte: string
  Img: string
  Estrapolazioni: string
}
type TypeCollezione = Array<TypeElemento>

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex RassegnaStampa getter Elenco')
    //console.log(state.Collezione)
    return state.Collezione
  }
}
const actions = {
  Elenco({ commit }) {
    //console.log('Vuex RassegnaStampa action Elenco')
    const Collezione = [] as TypeCollezione
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      res.forEach((doc: DataSnapshot) => {
        const obj = JSON.parse(JSON.stringify(doc.val()))
        //console.log(obj)
        const Payload = {
          Id: doc.key,
          Titolo: obj.Titolo,
          Data: obj.Data,
          Estrapolazioni: obj.Estrapolazioni,
          Fonte: obj.Fonte,
          Img: obj.Img
        }
        Collezione.push(Payload)
      })
      // console.log(Collezione)
      commit("Elenco", Collezione)
    })
    //console.log(commit, Collezione)
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    const obj = JSON.parse(JSON.stringify(Elemento))
    console.log("p", obj)
    const Payload = {
      Data: Utility.Data_aaaammgg_ggmmaaaa(obj.data),
      Titolo: obj.titolo,
      Fonte: obj.fonte,
      Img: obj.img,
      Estrapolazioni: obj.estrapolazioni ? obj.estrapolazioni : ""
    }
    console.log(Payload, commit)
    push(TabellaRef, Payload)
  }
}

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    state.Collezione = Collezione
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
