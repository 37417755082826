/* eslint-disable */
import { ref, get, DataSnapshot } from "firebase/database"
import db from "../Conn"
const TabellaRef = ref(db, "esami")
type TypeElemento = {
  id: string
  esame: string
  voto: number
  anno: number
  data: string
}
type TypeCollezione = Array<TypeElemento>
const state = {
  Esami: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Esami: TypeCollezione }) => {
    return state.Esami
  }
}
const actions = {
  Elenco: ({commit}) => {       
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
        let esami = [] as TypeCollezione
        res.forEach((doc: DataSnapshot) => {
            const obj = doc.val()
            const id = doc.key ? doc.key : ""
            const esame = obj.esame
            const voto = obj.voto
            const data = obj.data
            const anno = obj.anno
            esami.push({ id, esame, voto, anno, data })
        })                
        commit('Elenco',esami)
    })   
  }
}
const mutations = {
  Elenco: (state: { Esami: TypeCollezione }, esami: TypeCollezione) => {
    state.Esami = esami
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
