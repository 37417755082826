import Utility from "@/helpers/vuex"
import db from "../../Conn"
import { ref, get, push, remove, child, update } from "firebase/database"

function ElementoVuoto() {
  return {
    Id: "0",
    Nome: "",
    Cognome: "",
    Assunzione: "",
    Livello: "",
    Licenziamento: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  Nome: string
  Cognome: string
  Assunzione: string
  Livello: string
  Licenziamento: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>

const TabellaRef = ref(db, "assistenti")

const state = {
  Collezione: [] as TypeCollezione
}

const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex Assistenti getter Elenco')
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    //console.log('Vuex Assistenti action Elenco')
    const snapshot = get(TabellaRef)
    snapshot.then((res) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Nome = obj.Nome
        const Cognome = obj.Cognome
        const Assunzione = Utility.Data_ggmmaaaaa_aaaammgg(obj.Assunzione)
        const Livello = obj.Livello
        const Licenziamento =
          obj.Licenziamento === "vuoto"
            ? ""
            : Utility.Data_ggmmaaaaa_aaaammgg(obj.Licenziamento)
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          Nome,
          Cognome,
          Assunzione,
          Livello,
          Licenziamento,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    /* eslint-disable */
    //console.log('Vuex Assistenti action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item  
    const Payload = {
      Nome: obj.Nome,
      Cognome: obj.Cognome,
      Assunzione: Utility.Data_aaaammgg_ggmmaaaa(obj.Assunzione),
      Livello: obj.Livello
    }        
    console.log(Payload)
    push(TabellaRef, Payload).then((response: { key: any }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {        
        commit("Aggiungi", { Id, ...response.val() })
      })
    })        
  },
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Assistenti action Aggiorna')
    console.log(Elemento.item.Licenziamento)    
    const Id = Elemento.item.Id
    let Payload = {
      Nome: Elemento.item.Nome,
      Cognome: Elemento.item.Cognome,
      Assunzione: Utility.Data_aaaammgg_ggmmaaaa(Elemento.item.Assunzione),
      Livello: Elemento.item.Livello,
      Licenziamento: Elemento.item.Licenziamento === "" ? "vuoto" : Utility.Data_aaaammgg_ggmmaaaa(Elemento.item.Licenziamento)
    }
    console.log(Elemento.item.Licenziamento)    
    
    update(child(TabellaRef, Id), Payload).then(() => {
      get(child(TabellaRef, Id)).then(() => {
        commit //commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Assistenti action Elimina')
    const Id = Elemento.item.Id
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
}

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    //console.log('Vuex Assistenti mutation Elenco')
    return (state.Collezione = Collezione);
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex Assistenti mutation Aggiungi')
    const len = state.Collezione.length - 1
    state.Collezione[len].Id = Elemento.Id
    state.Collezione[len].Nome = Elemento.Nome
    state.Collezione[len].Cognome = Elemento.Cognome
    state.Collezione[len].Assunzione = Elemento.Assunzione
    state.Collezione[len].Livello = Elemento.Livello
    state.Collezione[len]._rowVariant = ""
    state.Collezione.push(ElementoVuoto())
  },
  //Aggiorna: (state, Elemento) => {},
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex Assistenti mutation Elimina')
    const index = state.Collezione.findIndex((item) => item.Id === Id)
    state.Collezione.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} 

