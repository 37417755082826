import {
  ref,
  get,
  push,
  update,
  remove,
  child,
  DataSnapshot
} from "firebase/database"
import db from "../Conn"
const TabellaRef = ref(db, "handiphonecategorie")
/*
function ElementoVuoto() {
  return {
    Id: "0",
    Nome: ""
  }
}
*/
type TypeElemento = {
  Id: string
  Nome: string
}
type TypeCollezione = Array<TypeElemento>
const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        //console.log(obj)
        const Id = doc.key ? doc.key : ""
        const Nome = obj.Nome
        Collezione.push({ Id, Nome })
      })
      //Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex HandiphoneCategorie action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item
    const Payload = {
      Nome: obj.Nome
    }
    push(TabellaRef, Payload).then((response: { key }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {
        commit("Aggiungi", { Id, ...response.val() })
      })
    })
  },
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    const obj = Elemento.item
    const Id = obj.Id
    update(child(TabellaRef, Id), {
      Nome: obj.Nome
    }).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },

  Elimina({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Assistenti action Elimina')
    const Id = Elemento.item.Id
    //console.log(Elemento, Id)
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })

    //commit("Elimina", Id)
  }
}
const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    state.Collezione = Collezione
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex Assistenti mutation Aggiungi')
    state.Collezione.push(Elemento)
  },
  Aggiorna: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex CalendarioFilosofico mutation Aggiorna')
    const CategoriaTrovata = state.Collezione.findIndex(
      (item) => item.Id === Elemento.Id
    )
    state.Collezione[CategoriaTrovata].Nome = Elemento.Nome
    console.log(state.Collezione)
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex Assistenti mutation Elimina')
    const index = state.Collezione.findIndex((item) => {
      console.log(item.Id, Id)
      if (item.Id === Id) {
        return item
      }
    })
    state.Collezione.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
