import {
  ref,
  get,
  child,
  push,
  update,
  remove,
  DataSnapshot
} from "firebase/database"
import db from "../../Conn"

function ElementoVuoto() {
  return {
    Id: "0",
    IdGruppo: "0",
    Nome: "",
    Utente: "",
    Password: "",
    Varie: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  IdGruppo: string
  Nome: string
  Utente: string
  Password: string
  Varie: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>
const TabellaRef = ref(db, "accountpassword")

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const IdGruppo = obj.IdGruppo
        const Nome = obj.Nome
        const Utente = obj.Utente
        const Password = obj.Password
        const Varie = obj.Varie ? obj.Varie : ""
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload = {
          Id,
          Nome,
          IdGruppo,
          Utente,
          Password,
          Varie,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      //console.log(Collezione)
      commit("Elenco", Collezione)
    })
  },
  Aggiungi: ({ commit }, Elemento: TypeElemento) => {
    //console.log('Vuex AccountPassword action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item
    const Payload = {
      Nome: obj.Nome,
      Utente: obj.Utente,
      Password: obj.Password,
      Varie: obj.Varie ? obj.Varie : "",
      IdGruppo: obj.IdGruppo
    }
    console.log(Payload)
    push(TabellaRef, Payload).then((response: { key }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {
        commit("Aggiungi", { Id, ...response.val() }) // Ci vuole Id perchè chiama la mutation
      })
    })
  },
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex AccountPassword action Aggiorna')
    const obj = Elemento.item
    const Id = obj.Id
    update(child(TabellaRef, Id), {
      Nome: obj.Nome,
      Utente: obj.Utente,
      Password: obj.Password,
      Varie: obj.Varie ? obj.Varie : "",
      IdGruppo: obj.IdGruppo
    }).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina({ commit }, Elemento: { key: string; item: TypeElemento }) {
    const Id = Elemento.item.Id
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
}
const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    //console.log(Collezione)
    state.Collezione = Collezione
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex AccountPassord mutation Aggiungi')
    const len = state.Collezione.length - 1
    state.Collezione[len].Id = Elemento.Id
    state.Collezione[len].Nome = Elemento.Nome
    state.Collezione[len].Utente = Elemento.Utente
    state.Collezione[len].Password = Elemento.Password
    state.Collezione[len].Varie = Elemento.Varie
    state.Collezione[len].IdGruppo = Elemento.IdGruppo
    state.Collezione[len].StaPerEssereInserita = false
    state.Collezione[len]._rowVariant = ""
    state.Collezione.push(ElementoVuoto())
  },
  Aggiorna: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex AccountPassword mutation Aggiorna')
    const AccountTrovato: TypeElemento = JSON.parse(
      JSON.stringify(state.Collezione.find((item) => item.Id === Elemento.Id))
    )
    AccountTrovato.Nome = Elemento.Nome
    AccountTrovato.Utente = Elemento.Utente
    AccountTrovato.Password = Elemento.Password
    AccountTrovato.Varie = Elemento.Varie
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex AccountPassword mutation Elimina')
    const index = state.Collezione.findIndex(
      (item: TypeElemento) => item.Id === Id
    )
    state.Collezione.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
