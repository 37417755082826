import db from "../../Conn"
import { ref, get, push, remove, child, update } from "firebase/database"

function ElementoVuoto() {
  return {
    Id: "0",
    Nome: "",
    IdSuperCategoria: "",
    Visibile: false,
    TestoDaAssociareCategoria: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  Nome: string
  IdSuperCategoria: string
  Visibile: boolean
  TestoDaAssociareCategoria: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>

const TabellaRef = ref(db, "categorie")

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex Categorie getter Elenco')
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    //console.log('Vuex Categorie action Elenco')
    const snapshot = get(TabellaRef)
    snapshot.then((res) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Nome = obj.Nome
        const IdSuperCategoria = obj.IdSuperCategoria
        const Visibile = obj.Visibile ? obj.Visibile : false
        const TestoDaAssociareCategoria = obj.TestoDaAssociareCategoria
          ? obj.TestoDaAssociareCategoria
          : ""
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          Nome,
          IdSuperCategoria,
          Visibile,
          TestoDaAssociareCategoria,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())

      commit("Elenco", Collezione)
    })
  },
  Aggiungi: ({ commit }, Elemento: TypeElemento) => {
    /* eslint-disable */
    //console.log('Vuex Categorie action Aggiungi')       
    const data = JSON.parse(JSON.stringify(Elemento))      
    const obj: TypeElemento = data.item;
    const Payload = {
      Nome: obj.Nome,
      IdSuperCategoria: obj.IdSuperCategoria,
      Visibile: obj.Visibile,
      TestoDaAssociareCategoria: obj.TestoDaAssociareCategoria
          ? obj.TestoDaAssociareCategoria
          : ""
    }    
    push(TabellaRef, Payload).then((response: { key: any }) => {
      const snapshot = get(child(TabellaRef, response.key));
      const Id = response.key;
      snapshot.then((response) => {
        commit("Aggiungi", { Id, ...response.val() });
      });
    });    
  },  
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Categorie action Aggiorna')    
    const Id = Elemento.item.Id;
    const Payload = {
      Nome: Elemento.item.Nome,
      IdSuperCategoria: Elemento.item.IdSuperCategoria,
      Visibile: Elemento.item.Visibile,
      TestoDaAssociareCategoria: Elemento.item.TestoDaAssociareCategoria
          ? Elemento.item.TestoDaAssociareCategoria
          : ""
    }    
    update(child(TabellaRef, Id), Payload).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },  
  Elimina({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Categorie action Elimina')
    const Id = Elemento.item.Id    
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
};

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    //console.log('Vuex Categorie mutation Elenco')
    return (state.Collezione = Collezione);
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    console.log('Vuex Categorie mutation Aggiungi')
    const len = state.Collezione.length - 1;
    state.Collezione[len].Id = Elemento.Id;
    state.Collezione[len].Nome = Elemento.Nome;
    state.Collezione[len]._rowVariant = "";
    state.Collezione.push(ElementoVuoto());
  },
    Aggiorna: (state, Elemento) => {
      //console.log(Elemento.Id, state.Collezione[1].Id)      
      const index = state.Collezione.findIndex((item) => item.Id === Elemento.Id)
      //console.log(index)      
      state.Collezione[index] = Elemento
    },
    Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
        //console.log('Vuex Supercategorie mutation Elimina')
        const index = state.Collezione.findIndex((item) => item.Id === Id)
        state.Collezione.splice(index, 1)
      }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
function commit(arg0: string, arg1: any) {
  throw new Error("Function not implemented.")
}

