import { createStore } from "vuex"
import Utente from "./modules/Utente.modules"
import PianoStudi from "./modules/PianoStudi.module"
import RassegnaStampa from "./modules/RassegnaStampa.module"
import CalendarioFilosofico from "./modules/CalendarioFilosofico.module"
import Assistenti from "./modules/BustePaga/Assistenti.module"
import BustePaga from "./modules/BustePaga/Buste.modules"
import SuperCategorie from "./modules/Banca/SuperCategorie.module"
import Categorie from "./modules/Banca/Categorie.module"
import Movimenti from "./modules/Banca/Movimenti.module"
import Compleanni from "./modules/Compleanni.module"
import GruppiPassword from "./modules/Password/Gruppi.module"
import AccountPassword from "./modules/Password/Account.module"
import CaseCondomini from "./modules/Condomini/Case.module"
import TipologieCondomini from "./modules/Condomini/Tipologie.module"
import ScadenzeCondomini from "./modules/Condomini/Scadenze.module"
import Landingpage from "./modules/Landingpage.modules"
import HandiphoneCategorie from "./modules/HandiphoneCategorie.module"
import HandiphoneCodiciFrasi from "./modules/HandiphoneFrasi.module"
import DistrettiBotulino from "./modules/Botulino/Distretti.module"
import MuscoliBotulino from "./modules/Botulino/Muscoli.module"
import QtyDateBotulino from "./modules/Botulino/Qtydate.module"

export default createStore({
  modules: {
    Utente,
    PianoStudi,
    RassegnaStampa,
    CalendarioFilosofico,
    Assistenti,
    BustePaga,
    SuperCategorie,
    Categorie,
    Movimenti,
    Compleanni,
    GruppiPassword,
    AccountPassword,
    CaseCondomini,
    TipologieCondomini,
    ScadenzeCondomini,
    Landingpage,
    HandiphoneCategorie,
    HandiphoneCodiciFrasi,
    DistrettiBotulino,
    MuscoliBotulino,
    QtyDateBotulino
  }
})
