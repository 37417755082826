import {
  ref,
  get,
  child,
  push,
  update,
  remove,
  DataSnapshot
} from "firebase/database"
import db from "../../Conn"
const TabellaRef = ref(db, "gruppipassword")

function ElementoVuoto() {
  //console.log('Vuex CalendarioFilosofico ElementoVuoto')
  return {
    Id: "0",
    Nome: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}
type TypeElemento = {
  Id: string
  Nome: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}
type TypeCollezione = Array<TypeElemento>
const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Nome = obj.Nome
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          Nome,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    const data = JSON.parse(JSON.stringify(Elemento))
    //console.log(data)
    const obj: TypeElemento = data.item
    const Payload = {
      Nome: obj.Nome
    }
    //console.log(Payload)
    push(TabellaRef, Payload).then((response: { key }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {
        commit("Aggiungi", { Id, ...response.val() }) // Ci vuole Id perchè chiama la mutation
      })
    })
  },
  Aggiorna(
    { commit },
    Elemento: {
      key: string
      item: TypeElemento
    }
  ) {
    //console.log('Vuex GruppiPassword action Aggiorna')
    const obj = Elemento.item
    const Id = obj.Id
    update(child(TabellaRef, Id), {
      Nome: obj.Nome
    }).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex GruppiPassword action Elimina')
    const Id = Elemento.item.Id
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
}
const mutations = {
  Elenco: (
    //console.log('Vuex GruppiPassword mutation Elenco')
    state: { Collezione: TypeCollezione },
    collezione: TypeCollezione
  ) => {
    //console.log(collezione)
    state.Collezione = collezione
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex GruppiPassword mutation Aggiungi')
    const len = state.Collezione.length - 1
    state.Collezione[len].Id = Elemento.Id
    state.Collezione[len].Nome = Elemento.Nome
    state.Collezione.push(ElementoVuoto())
  },
  Aggiorna: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex GruppiPassword mutation Aggiorna')
    const GruppoTrovato: TypeElemento = JSON.parse(
      JSON.stringify(state.Collezione.find((item) => item.Id === Elemento.Id))
    )
    GruppoTrovato.Nome = Elemento.Nome
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex GruppiPassword mutation Elimina')
    const index = state.Collezione.findIndex(
      (item: TypeElemento) => item.Id === Id
    )
    state.Collezione.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
