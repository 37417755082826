import { ref, get, DataSnapshot } from "firebase/database"
import db from "../Conn"
const TabellaRef = ref(db, "compleanni")
type TypeElemento = {
  Id: string
  Nome: string
  Cognome: string
  Giorno: string
  Mese: string
  Anno: string
  Data: string
  DataInglese: string
  Test: boolean
}
type TypeCollezione = Array<TypeElemento>
const state = {
  DateNascita: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { DateNascita: TypeCollezione }) => {
    return state.DateNascita
  }
}
const actions = {
  Elenco: ({ commit }) => {
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const DateNascita = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Nome = obj.Nome
        const Cognome = obj.Cognome
        let Giorno = obj.Giorno
        if (Giorno < 10) {
          Giorno = "0" + Giorno
        }
        let Mese = obj.Mese
        if (Mese < 10) {
          Mese = "0" + Mese
        }
        const Anno = obj.Anno
        const Data = Giorno + "/" + Mese + "/" + Anno
        const DataInglese = "2024-" + Mese + "-" + Giorno
        const Test = new Date(DataInglese) < new Date()
        DateNascita.push({
          Id,
          Nome,
          Cognome,
          Giorno,
          Mese,
          Anno,
          Data,
          DataInglese,
          Test
        })
      })
      DateNascita.sort((a: TypeElemento, b: TypeElemento) => {
        return (
          new Date(a.DataInglese).getTime() - new Date(b.DataInglese).getTime()
        )
      })
      commit("Elenco", DateNascita)
    })
  }
}
const mutations = {
  Elenco: (
    state: { DateNascita: TypeCollezione },
    compleanni: TypeCollezione
  ) => {
    //console.log(compleanni)
    state.DateNascita = compleanni
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
