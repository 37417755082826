import {
  ref,
  get,
  child,
  push,
  update,
  remove,
  DataSnapshot
} from "firebase/database"
import db from "../Conn"
import Utility from "@/helpers/vuex"
const TabellaRef = ref(db, "calendariofilosofico")

function ElementoVuoto() {
  //console.log('Vuex CalendarioFilosofico ElementoVuoto')
  return {
    Id: "0",
    Data: "",
    Anno: "0",
    Mese: "0",
    Giorno: "0",
    Frase: "",
    Firma: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  Data: string
  Anno: string
  Mese: string
  Giorno: string
  Frase: string
  Firma: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex CalendarioFilosofico getter Elenco', state.Collezione)
    return state.Collezione
  }
}
const actions = {
  Elenco({ commit }) {
    //console.log('Vuex CalendarioFilosofico action Elenco')
    const Collezione = [] as TypeCollezione
    const snapshot = get(TabellaRef)
    snapshot
      .then((res: DataSnapshot) => {
        res.forEach((doc: DataSnapshot) => {
          const obj = doc.val()
          const Data = Utility.Data_ggmmaaaaa_aaaammgg(obj.Data)
          const Id = doc.key ? doc.key : ""
          const Anno = obj.Data.substr(6, 10)
          const Mese = obj.Data.substr(3, 2)
          const Giorno = obj.Data.substr(0, 2)
          const Frase = obj.Frase
          const Firma = obj.Firma
          const StaPerEssereInserita = false
          const StaPerEssereModificata = false
          const StaPerEssereEliminata = false
          const Payload: TypeElemento = {
            Id,
            Data,
            Anno,
            Mese,
            Giorno,
            Frase,
            Firma,
            StaPerEssereInserita,
            StaPerEssereModificata,
            StaPerEssereEliminata,
            _rowVariant: ""
          }
          Collezione.push(Payload)
        })
        Collezione.push(ElementoVuoto())
        commit("Elenco", Collezione)
      })
      .catch((e) => {
        console.log(e)
      })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item
    const Payload = {
      Data: Utility.Data_aaaammgg_ggmmaaaa(obj.Data),
      Frase: obj.Frase,
      Firma: obj.Firma
    }
    console.log(Payload)
    push(TabellaRef, Payload).then((response: { key }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {
        commit("Aggiungi", { Id, ...response.val() }) // Ci vuole Id perchè chiama la mutation
      })
    })
  },
  Aggiorna(
    { commit },
    Elemento: {
      key: string
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Aggiorna')
    const obj = Elemento.item
    const Id = obj.Id
    update(child(TabellaRef, Id), {
      Data: Utility.Data_aaaammgg_ggmmaaaa(obj.Data),
      Frase: obj.Frase,
      Firma: obj.Firma
    }).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Elimina')
    const Id = Elemento.item.Id
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
}

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    //console.log('Vuex CalendarioFilosofico mutation Elenco')
    return (state.Collezione = Collezione)
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex CalendarioFilosofico mutation Aggiungi')
    const len = state.Collezione.length - 1
    state.Collezione[len].Id = Elemento.Id
    const data = Elemento.Data
    state.Collezione[len].Anno = data.substr(6, 10)
    state.Collezione[len].Mese = data.substr(3, 2)
    state.Collezione[len].Giorno = data.substr(0, 2)
    state.Collezione[len].StaPerEssereInserita = false
    state.Collezione[len]._rowVariant = ""
    state.Collezione.push(ElementoVuoto())
  },
  Aggiorna: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex CalendarioFilosofico mutation Aggiorna')
    const FraseTrovata: TypeElemento = JSON.parse(
      JSON.stringify(state.Collezione.find((item) => item.Id === Elemento.Id))
    )
    FraseTrovata.Anno = Elemento.Data.substr(6, 10)
    FraseTrovata.Mese = Elemento.Data.substr(3, 2)
    FraseTrovata.Giorno = Elemento.Data.substr(0, 2)
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex CalendarioFilosofico mutation Elimina')
    const index = state.Collezione.findIndex(
      (item: TypeElemento) => item.Id === Id
    )
    state.Collezione.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
