import {
  ref,
  get,
  child,
  push,
  update,
  remove,
  DataSnapshot
} from "firebase/database"
import db from "../../Conn"

function ElementoVuoto() {
  return {
    Id: "0",
    IdCasa: "",
    IdTipologia: "",
    Importo: "",
    Data: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  IdCasa: string
  IdTipologia: string
  Importo: string
  Data: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>
const TabellaRef = ref(db, "scadenzecondominio")

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log(state.Collezione)
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    //console.log(">")
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const IdCasa = obj.IdCasa
        const IdTipologia = obj.IdTipologia
        const Importo = obj.Importo
        const Data = obj.Data
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload = {
          Id,
          IdCasa,
          IdTipologia,
          Importo,
          Data,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      //console.log(">>", Collezione)
      commit("Elenco", Collezione)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item
    const Payload = {
      IdCasa: obj.IdCasa,
      IdTipologia: obj.IdTipologia,
      Importo: obj.Importo,
      Data: obj.Data
    }
    console.log(Payload)
    push(TabellaRef, Payload).then((response: { key }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {
        commit("Aggiungi", { Id, ...response.val() }) // Ci vuole Id perchè chiama la mutation
      })
    })
  },
  Aggiorna(
    { commit },
    Elemento: {
      key: string
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Aggiorna')
    const obj = Elemento.item
    const Id = obj.Id
    update(child(TabellaRef, Id), {
      IdCasa: obj.IdCasa,
      IdTipologia: obj.IdTipologia,
      Importo: obj.Importo,
      Data: obj.Data
    }).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Elimina')
    const Id = Elemento.item.Id
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
}

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    //console.log(Collezione)
    state.Collezione = Collezione
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex CalendarioFilosofico mutation Aggiungi')
    const len = state.Collezione.length - 1
    state.Collezione[len].Id = Elemento.Id
    state.Collezione[len].IdCasa = Elemento.IdCasa
    state.Collezione[len].IdTipologia = Elemento.IdTipologia
    state.Collezione[len].Importo = Elemento.Importo
    state.Collezione[len].Data = Elemento.Data
    state.Collezione[len]._rowVariant = ""
    state.Collezione.push(ElementoVuoto())
  },
  Aggiorna: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex CalendarioFilosofico mutation Aggiorna')
    const ScadenzaTrovata: TypeElemento = JSON.parse(
      JSON.stringify(state.Collezione.find((item) => item.Id === Elemento.Id))
    )
    ScadenzaTrovata.IdCasa = Elemento.IdCasa
    ScadenzaTrovata.IdTipologia = Elemento.IdTipologia
    ScadenzaTrovata.Importo = Elemento.Importo
    ScadenzaTrovata.Data = Elemento.Data
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex CalendarioFilosofico mutation Elimina')
    const index = state.Collezione.findIndex(
      (item: TypeElemento) => item.Id === Id
    )
    state.Collezione.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
