<template>
  <div>
    <span>
      <div
        id="Debug"
        style="
          text-align: center;
          background-color: antiquewhite;
          z-index: 6;
          display: none;
        "
      >
        &nbsp;
      </div>
    </span>
    <div
      v-if="Token != ''"
      style="
        position: fixed;
        width: 490px;
        color: #fff;
        text-align: center;
        top: 0;
        height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 10;
        background-color: var(--Background);
        opacity: 0.6;
      "
    >
      <table style="width: 500px">
        <tr v-for="(item, index) in CompleanniFuturi" :key="index">
          <td :class="item.Test ? 'giornocompleanno' : ''" style="width: 150px">
            {{ item.Nome }}
          </td>
          <td :class="item.Test ? 'giornocompleanno' : ''" style="width: 150px">
            {{ item.Cognome }}
          </td>
          <td :class="item.Test ? 'giornocompleanno' : ''">
            {{ item.Giorno }} {{ MeseDaNumeroANome(item.Mese) }} {{ item.Anno }}
          </td>
        </tr>
      </table>
    </div>
    <div style="position: fixed; top: 0; right: 0">
      <b-button
        style="float: right"
        @click="NascondiMenu = !NascondiMenu"
        v-if="Token != ''"
        >si/no</b-button
      >
      <!--
      <router-link
        :to="{ name: 'Login' }"
        class="btn"
        v-else
        style="float: right"
      >
        login
      </router-link>
    --></div>
    <Menu v-if="NascondiMenu" />
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { mapActions, mapGetters } from "vuex"
import MyMixin from "@/helpers/MyMixin"

import Menu from "@/components/Menu"

export default defineComponent({
  name: "App",
  mixins: [MyMixin],
  data() {
    return {
      larghezza: 0,
      Debug: "Debug",
      Token: "",
      NascondiMenu: true,
      CompleanniFuturi: []
    }
  },
  mounted() {
    this.DebugMethod()
    if (localStorage.getItem("Token")) {
      //console.log("aa")
      this.Token = localStorage.getItem("Token")
      setTimeout(() => {
        this.Aggiorna()
      }, 100)
    }
    this.DebugMethod()
    this.Esami()
    this.Articoli()
    this.Frasi()
    this.Landingpage()

    //this.Assistenti()
    //this.BustePaga()
    //this.SuperCategorie()
    //this.Categorie()
    //this.Movimenti()
  },
  methods: {
    ...mapActions({
      Login: "Utente/Login",
      Esami: "PianoStudi/Elenco",
      Articoli: "RassegnaStampa/Elenco",
      Frasi: "CalendarioFilosofico/Elenco",
      Assistenti: "Assistenti/Elenco",
      BustePaga: "BustePaga/Elenco",
      SuperCategorie: "SuperCategorie/Elenco",
      Categorie: "Categorie/Elenco",
      Movimenti: "Movimenti/Elenco",
      ActionCompleanni: "Compleanni/Elenco",
      GruppiPassword: "GruppiPassword/Elenco",
      AccountPassword: "AccountPassword/Elenco",
      CaseCondomini: "CaseCondomini/Elenco",
      TipologieCondomini: "TipologieCondomini/Elenco",
      ScadenzeCondomini: "ScadenzeCondomini/Elenco",
      Landingpage: "Landingpage/Elenco",
      HandiphoneCategorie: "HandiphoneCategorie/Elenco",
      HandiphoneCodiciFrasi: "HandiphoneCodiciFrasi/Elenco",
      DistrettiMuscolari: "DistrettiBotulino/Elenco",
      MuscoliBotulino: "MuscoliBotulino/Elenco",
      QtyDateBotulino: "QtyDateBotulino/Elenco"
    }),
    Aggiorna() {
      this.Login().then(() => {
        setTimeout(() => {
          this.SuperCategorie()
          this.Categorie()
          this.Movimenti()
          this.Assistenti()
          this.BustePaga()
          this.ActionCompleanni()
          this.GruppiPassword()
          this.AccountPassword()
          this.CaseCondomini()
          this.TipologieCondomini()
          this.ScadenzeCondomini()
          this.HandiphoneCategorie()
          this.HandiphoneCodiciFrasi()
          this.DistrettiMuscolari()
          this.MuscoliBotulino()
          this.QtyDateBotulino()
        }, 900)
      })
    },
    DebugMethod() {
      this.larghezza = window.innerWidth
      console.log(localStorage.getItem("Debug"))
      if (!localStorage.getItem("Debug")) {
        const styles = getComputedStyle(document.documentElement)
        const background = styles.getPropertyValue("--Background")
        const menu = styles.getPropertyValue("--Menu")
        document.documentElement.style.setProperty("--xs", background)
        document.documentElement.style.setProperty("--sm", background)
        document.documentElement.style.setProperty("--md", background)
        document.documentElement.style.setProperty("--lg", background)
        document.documentElement.style.setProperty("--xl", background)
        document.documentElement.style.setProperty("--xxl", background)

        document.documentElement.style.setProperty("--xs_dark", menu)
        document.documentElement.style.setProperty("--sm_dark", menu)
        document.documentElement.style.setProperty("--md_dark", menu)
        document.documentElement.style.setProperty("--lg_dark", menu)
        document.documentElement.style.setProperty("--xl_dark", menu)
        document.documentElement.style.setProperty("--xxl_dark", menu)

        document.getElementById("Debug").style.display = "none"
      } else {
        document.documentElement.style.setProperty("--xs", "aqua")
        document.documentElement.style.setProperty("--sm", "blue")
        document.documentElement.style.setProperty("--md", "green")
        document.documentElement.style.setProperty("--lg", "brown")
        document.documentElement.style.setProperty("--xl", "coral")
        document.documentElement.style.setProperty("--xxl", "cadetblue")

        document.documentElement.style.setProperty("--xs_dark", "#006666")
        document.documentElement.style.setProperty("--sm_dark", "#000066")
        document.documentElement.style.setProperty("--md_dark", "#11a00cff")
        document.documentElement.style.setProperty("--lg_dark", "#660000")
        document.documentElement.style.setProperty("--xl_dark", "#b63100")
        document.documentElement.style.setProperty("--xxl_dark", "#cccaa0")
        //--bs-btn-bg: yellow

        document.getElementById("Debug").style.display = "block"
      }
    }
  },
  computed: {
    ...mapGetters({
      Compleanni: "Compleanni/Elenco",
      Esamig: "Esami/Elenco"
    })
  },
  watch: {
    Compleanni() {
      let Oggi = new Date()
      Oggi = new Date(Oggi.setDate(Oggi.getDate() - 1))
      //console.log(Oggi)
      this.Compleanni.forEach((item) => {
        if (new Date(item.DataInglese).getTime() > Oggi.getTime()) {
          this.CompleanniFuturi.push(item)
        }
      })
    }
  },
  components: {
    Menu
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.giornocompleanno {
  font-size: 22px;
  background-color: yellowgreen;
  color: #0000ff;
  font-weight: 600;
}
</style>
