import { ref, get, DataSnapshot, push, update, child } from "firebase/database"
import db from "../../Conn"
const TabellaRef = ref(db, "muscolibotulino")

function ElementoVuoto() {
  return {
    Id: "0",
    IdDistretto: "0",
    Sigla: "",
    Descrizione: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}
type TypeElemento = {
  Id: string
  Sigla: string
  IdDistretto: string
  Descrizione: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex distrettibotulino getter Elenco', state.Collezione)
    return state.Collezione
  }
}

const actions = {
  Elenco({ commit }) {
    //console.log('Vuex Muscoloibutonllkno action Elenco')
    const Collezione = [] as TypeCollezione
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Descrizione = obj.Descrizione
        const Sigla = obj.Sigla
        const IdDistretto = obj.IdDistretto ? obj.IdDistretto : "0"
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          IdDistretto,
          Descrizione,
          Sigla,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    const obj = JSON.parse(JSON.stringify(Elemento))
    console.log(obj)
    //const obj: TypeElemento = data.item
    const Payload = {
      Sigla: obj.Sigla,
      Descrizione: obj.Descrizione,
      IdDistretto: obj.IdDistretto
    }
    console.log(Payload, commit)
    push(TabellaRef, Payload).then((response: { key: any }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {
        //commit("Aggiungi", { Id, ...response.val() })
      })
    })
  },
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    console.log("agg", commit, Elemento, child)
    const Id = Elemento.item.Id
    const Playload = {
      Sigla: Elemento.item.Sigla,
      Descrizione: Elemento.item.Descrizione,
      IdDistretto: Elemento.item.IdDistretto
    }
    update(child(TabellaRef, Id), Playload).then(() => {
      get(child(TabellaRef, Id)).then(() => {
        commit //commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  }
}
const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    state.Collezione = Collezione
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
