import { ref, get, DataSnapshot } from "firebase/database"
import db from "../../Conn"
const TabellaRef = ref(db, "/distrettibotulino")

function ElementoVuoto() {
  return {
    Id: "0",
    Sigla: "",
    Descrizione: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}
type TypeElemento = {
  Id: string
  Descrizione: string
  Sigla: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex distrettibotulino getter Elenco', state.Collezione)
    return state.Collezione
  }
}

const actions = {
  Elenco({ commit }) {
    //console.log('Vuex Distrettibutonllkno action Elenco')
    const Collezione = [] as TypeCollezione
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Descrizione = obj.Descrizione
        const Sigla = obj.Sigla
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          Descrizione,
          Sigla,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  }
}
const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    state.Collezione = Collezione
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
