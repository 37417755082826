import db from "../../Conn"
import {
  ref,
  get,
  push,
  remove,
  child,
  update,
  DataSnapshot
} from "firebase/database"

import Utility from "@/helpers/vuex"

function ElementoVuoto() {
  //console.log('Vuex Buste ElementoVuoto')
  return {
    Id: "0",
    IdAssistente: "",
    Anno: "",
    Mese: "",
    NomeMese: "",
    Netto: "",
    Lordo: "",
    FerieAp: "",
    FerieMat: "",
    FerieGod: "",
    FerieResidue: 0,
    Mav: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  IdAssistente: string
  Anno: string
  Mese: string
  NomeMese: string | undefined
  Netto: string
  Lordo: string
  FerieAp: string
  FerieMat: string
  FerieGod: string
  FerieResidue: number | string
  Mav: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}
type TypeElementoAssistente = {
  Id: string
  Nome: string
  Cognome: string
  Assunzione: string
  Livello: string
  Licenziamento: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>
type TypeCollezioneAssistente = Array<TypeCollezioneAssistente>

const TabellaRef = ref(db, "buste")

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log('Vuex Buste getter Elenco')
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    //console.log('Vuex Buste action Elenco')
    const Collezione = [] as TypeCollezione
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const FerieAp0 = obj.FerieAp ? obj.FerieAp : 0
        const FerieMat0 = obj.FerieMat ? obj.FerieMat : 0
        const FerieGod0 = obj.FerieGod ? obj.FerieGod : 0
        const FerieResidue0 = FerieAp0 + FerieMat0 - FerieGod0
        const Id = doc.key ? doc.key : ""
        const IdAssistente = obj.IdAssistente
        const Anno = obj.Anno
        const Mese = obj.Mese
        const NomeMese = Utility.NumeroMeseANomeMese(obj.mese)
        const Netto = obj.Netto
        const Lordo = obj.Lordo
        const FerieAp = obj.FerieAp
        const FerieMat = obj.FerieMat
        const FerieGod = obj.FerieGod
        const FerieResidue = FerieResidue0.toFixed(2)
        const Mav = obj.Mav
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          IdAssistente,
          Anno,
          Mese,
          NomeMese,
          Netto,
          Lordo,
          FerieAp,
          FerieMat,
          FerieGod,
          FerieResidue,
          Mav,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },
  Aggiungi({ commit, rootGetters }, Busta: TypeElemento) {
    //console.log('Vuex Buste action Aggiungi')
    /* eslint-disable */
    const Assistenti = rootGetters["Assistenti/Elenco"]
    let AssistentiConContrattoInEssere = Assistenti.map(
      (item: TypeElementoAssistente) => {
        if (item.Licenziamento === "") {
          return item
        }
      }
    ).filter((item: TypeCollezioneAssistente) => {
      return typeof item !== "undefined"
    });
    AssistentiConContrattoInEssere = JSON.parse(
      JSON.stringify(AssistentiConContrattoInEssere)
    )
    let Payload: { IdAssistente: string; Anno: string; Mese: string }
    AssistentiConContrattoInEssere.forEach((item: TypeElemento) => {
      Payload = {
        IdAssistente: item.Id,
        Anno: Busta.Anno,
        Mese: Busta.Mese,
      }
      push(TabellaRef, Payload).then((response: { key: any }) => {
        const snapshot = get(child(TabellaRef, response.key))
        const Id = response.key
        snapshot.then((response) => {
          commit("Aggiungi", { Id, ...response.val() })
        })
      })
    })
  },
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Buste action Aggiorna')
    const obj = Elemento.item
    console.log(parseFloat(obj.FerieAp))    
    const Id = obj.Id    
    let Payload = {
      Netto: parseFloat(obj.Netto),
      Lordo: parseFloat(obj.Lordo),
      FerieAp: parseFloat(obj.FerieAp),
      FerieMat: parseFloat(obj.FerieMat),
      FerieGod: parseFloat(obj.FerieGod),
      Mav: (obj.Mav) ? parseFloat(obj.Mav) : 0,
    }
    update(child(TabellaRef, Id), Payload).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        commit("Aggiorna", { ...response.val(), id: response.key })
      })
    })
  },
  Elimina({ state, commit }, Elemento: { key: string; item: TypeElemento }) {
    const IdBuste = state.Collezione.map((item: TypeElemento)=>{
      if (item.Anno === Elemento.item.Anno && item.Mese === Elemento.item.Mese){
        return item.Id
      }
    }).filter((item: TypeElemento) => { return (typeof (item) !== 'undefined') })  
      IdBuste.forEach((item: string) => {
        remove(child(TabellaRef, item)).then(() => {
          commit("Elimina", item)
        })
      })
  },
  AggiungiArchivio({ commit }, Busta) {
    console.log(Busta)    
    const Payload = {
      IdAssistente: Busta.assistentiId,
      Anno: Busta.anno,
      Mese: Busta.mese,
      Netto: Busta.netto,
      Lordo: Busta.lordo,
      FerieAp: Busta.ferie_ap,
      FerieMat: Busta.ferie_mat,
      FerieGod: Busta.ferie_god,
      Mav: Busta.mav
    }    
    console.log(Payload);
    push(TabellaRef, Payload)    
  }
}

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    state.Collezione = Collezione
  },
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log(state, Elemento)
    const len = state.Collezione.length - 1
    state.Collezione[len].Id = Elemento.Id
    state.Collezione[len].IdAssistente = Elemento.IdAssistente
    state.Collezione[len].Anno = Elemento.Anno
    state.Collezione[len].Mese = Elemento.Mese
    state.Collezione[len].Netto = Elemento.Netto
    state.Collezione[len].Lordo = Elemento.Lordo
    state.Collezione[len].FerieAp = Elemento.FerieAp
    state.Collezione[len].FerieMat = Elemento.FerieMat
    state.Collezione[len].FerieResidue = 0
    state.Collezione[len].Mav = Elemento.Mav
    state.Collezione.push(ElementoVuoto())
  },
  Aggiorna: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    console.log(Elemento);
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    ///console.log('Vuex Assistenti mutation Elimina')
    const index = state.Collezione.findIndex((item) => item.Id === Id)
    state.Collezione.splice(index, 1)
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function commit(arg0: string, arg1: any) {
  throw new Error("Function not implemented.")
}
