type TypeObj = Array<TypeItem>

type TypeItem = {
  Id: string
  _rowVariant: string
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  StaPerEssereInserita: boolean
  StaPerEssereCambiataCategoria?: boolean
}

import { defineComponent } from "vue"

export default defineComponent({
  name: "MyMixin",
  data() {
    return {
      Collezione: [] as TypeObj
    }
  },
  methods: {
    BottoniAzione(Id: string, Tipo = "Modifica") {
      console.log("MyMixin BottoneAzione", Id)
      this.Reset()
      this.Collezione.forEach((item) => {
        if (item.Id === Id) {
          console.log("W")
          item._rowVariant =
            Tipo === "Inserisci"
              ? "primary"
              : Tipo === "Modifica"
              ? "info"
              : "danger"
          item.StaPerEssereModificata = Tipo === "Modifica" ? true : false
          if (Tipo === "Inserisci") item.StaPerEssereInserita = true
          if (Tipo === "Elimina") item.StaPerEssereEliminata = true
        }
      })
    },
    CambiaCategoria(IdMovimento) {
      console.log("CambiaCategoria")
      this.Reset()
      this.Collezione.forEach((item) => {
        if (item.Id === IdMovimento) {
          //console.log(item)
          item.StaPerEssereCambiataCategoria = true
        }
      })
    },
    OperazioniSuFirebase(Action, data: TypeItem) {
      console.log("MyMixin OperazioniSulServer", data)
      data = JSON.parse(JSON.stringify(data))
      Action(data).then(() => {
        this.Reset()
      })
    },
    Chiudi() {
      //console.log('MyMixin Chiudi');
      this.Reset()
    },
    Reset() {
      //console.log('MyMixin Reset')
      this.Collezione.forEach((item: TypeItem) => {
        item.StaPerEssereModificata = false
        item.StaPerEssereEliminata = false
        item.StaPerEssereInserita = false
        item.StaPerEssereCambiataCategoria = false
        item._rowVariant = ""
      })
    }
  },
  computed: {
    Data_aaaammgg_ggmmaaaa() {
      return function (obj: string) {
        //console.log("MyMixin Data_aaaammgg_ggmmaaaa")
        //console.log(obj)
        if (!obj) {
          return ""
        }
        const gg = obj.substr(8, 2)
        const mm = obj.substr(5, 2)
        const aaaa = obj.substr(0, 4)
        return gg + "-" + mm + "-" + aaaa
      }
    },
    Data_ggmmaaaa_aaaammgg() {
      return function (Data: string) {
        console.log(Data)
        const gg = Data.substring(0, 2)
        const mm = Data.substring(3, 5)
        const aaaa = Data.substring(6, 10)
        console.log(gg, mm, aaaa)
        console.log("--------")
        /*const tmp = new Date(Data)
        console.log(tmp)

        /*  const anno = tmp.getFullYear();
        let mese: number | string = tmp.getMonth() + 1;
        if (mese < 10) mese = "0" + mese;
        let giorno: number | string = tmp.getDate();
        if (giorno < 10) giorno = "0" + giorno;
        console.log(giorno+'-'+mese+'-'+anno);
        
        return '2000-09-09'
        return giorno + "-" + mese + "-" + anno;
        ******/
        return aaaa + "-" + mm + "-" + gg
      }
    },
    /*,
    Data_NewDate_ggmmaaaa(Data: Date){

      const tmp = new Date(Data)
      console.log(tmp)

      const anno = tmp.getFullYear();
      let mese: number | string = tmp.getMonth() + 1;
      if (mese < 10) mese = "0" + mese;
      let giorno: number | string = tmp.getDate();
      if (giorno < 10) giorno = "0" + giorno;
      console.log(giorno+'-'+mese+'-'+anno);
      
      //return '2000-09-09'
      return giorno + "-" + mese + "-" + anno;      
    },*/
    Data_NewDate_aaaammgg() {
      return function (Data: Date) {
        //console.log('MyMixin 'Data_NewDate_aaaammgg)
        if (Data) {
          const tmp = new Date(Data)
          let giorno: number | string = tmp.getDate()
          let mese: number | string = tmp.getMonth() + 1
          const anno = tmp.getFullYear()
          if (giorno < 10) {
            giorno = "0" + giorno
          }
          if (mese < 10) {
            mese = "0" + mese
          }
          return anno + "-" + mese + "-" + giorno
        } else {
          return ""
        }
      }
    },
    Data_NewDate_ggmmaaaa() {
      return function (Data: Date) {
        //console.log('MyMixin 'Data_NewDate_aaaammgg)
        if (Data) {
          const tmp = new Date(Data)
          let giorno: number | string = tmp.getDate()
          let mese: number | string = tmp.getMonth() + 1
          const anno = tmp.getFullYear()
          if (giorno < 10) {
            giorno = "0" + giorno
          }
          if (mese < 10) {
            mese = "0" + mese
          }
          return giorno + "-" + mese + "-" + anno
        } else {
          return ""
        }
      }
    } /*
    DataAAnnoMeseGiorno() {
      return function (obj: string | number | Date) {
        const data = new Date(obj);
        return {
          Anno: data.getFullYear(),
          Mese: data.getMonth() + 1,
          Giorno: data.getDate(),
        };
      };
    },*/,
    MeseDaNumeroANome() {
      return function (Numero: string | number) {
        //console.log('Mymixin MeseDaNumeroANome')
        let nome = ""
        switch (Numero) {
          case "01":
          case 1:
            nome = "gennaio"
            break
          case "02":
          case 2:
            nome = "febbraio"
            break
          case "03":
          case 3:
            nome = "marzo"
            break
          case "04":
          case 4:
            nome = "aprile"
            break
          case "05":
          case 5:
            nome = "maggio"
            break
          case "06":
          case 6:
            nome = "giugno"
            break
          case "07":
          case 7:
            nome = "luglio"
            break
          case "08":
          case 8:
            nome = "agosto"
            break
          case "09":
          case 9:
            nome = "settembre"
            break
          case "10":
          case 10:
            nome = "ottobre"
            break
          case "11":
          case 11:
            nome = "novembre"
            break
          case "12":
          case 12:
            nome = "dicembre"
            break
          default:
            nome = ""
        }
        return nome
      }
    },
    Decimali() {
      return function (number) {
        const abc = number
        if (abc === -104550.9) {
          console.log(number)
        }
        number = Math.abs(number)
        let ris = ""
        let decPlaces
        let decSep
        const thouSep = "."
        /*
      if (number == null) {
        number = ""
      } else {
        */
        // eslint-disable-next-line no-unused-expressions, no-sequences
        ;(decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
          (decSep = typeof decSep === "undefined" ? "," : decSep)
        //thouSep = typeof thouSep === "undefined" ? "." : thouSep
        //console.log(decPlaces)
        const sign = number < 0 ? "-" : ""
        const i = String(
          parseInt((number = Math.abs(Number(number) || 0).toFixed(2)))
        )
        //console.log(i)
        // eslint-disable-next-line no-var
        let j
        j = (j = i.length) > 3 ? j % 3 : 0
        //console.log(i.length, j)
        ris =
          sign +
          (j ? i.substr(0, j) + thouSep : "") +
          i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + "thouSep") +
          (decPlaces
            ? "," +
              Math.abs(number - parseFloat(i))
                .toFixed(decPlaces)
                .slice(2)
            : "")
        if (abc === -104550.9) {
          console.log("Q", ris)
        }
        return ris
      }
    },
    ValutaDaIngleseAItalia: function () {
      return function (num) {
        let Newvalore
        const Somma = Math.abs(num).toFixed(2)
        const Valore = Somma.replace(/[^\d]/g, "").replace(/^0+/g, "")
        const Intero = parseInt(
          Valore.toString().substring(0, Valore.toString().length - 2)
        )
        const Decimali = Valore.toString().substring(
          Valore.toString().length - 2,
          Valore.toString().length
        )
        if (Intero.toString().length > 3) {
          const Numero = Intero.toString().substr(
            Intero.toString().length - 3,
            3
          )
          const Migliaia = Intero.toString().substring(
            0,
            Intero.toString().length - 3
          )
          Newvalore = Migliaia + "." + Numero + "," + Decimali
        } else {
          Newvalore = Intero + "," + Decimali
        }
        return Newvalore
      }
    },
    Round: function () {
      return function (num: number, decimals: number) {
        const t = Math.pow(10, decimals)
        let tmp = (
          Math.round(
            num * t +
              (decimals > 0 ? 1 : 0) *
                (Math.sign(num) * (10 / Math.pow(100, decimals)))
          ) / t
        ).toFixed(decimals)
        const intero = Math.trunc(parseInt(tmp)).toString()
        if (intero.length > 3) {
          //console.log(tmp)
          tmp = tmp.replace(".", ",")
          tmp =
            tmp.toString().substring(0, tmp.length - 6) +
            "." +
            tmp.substring(1, tmp.length)
          //console.log(tmp)
        } else {
          tmp = tmp.replace(".", ",")
        }
        return tmp
      }
    }
  }
})
