import {
  ref,
  get,
  push,
  child,
  update,
  remove,
  DataSnapshot
} from "firebase/database"
import db from "../Conn"
const TabellaRef = ref(db, "handiphonefrasi")

function ElementoVuoto() {
  return {
    Id: "0",
    Codice: "",
    CategoriaId: "",
    Frase: "",
    Posizione: 0,
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  Codice: string
  CategoriaId: string
  Frase: string
  Posizione: number
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  _rowVariant: string
}
type TypeCollezione = Array<TypeElemento>
const state = {
  HandiphoneFrasi: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { HandiphoneFrasi: TypeCollezione }) => {
    return state.HandiphoneFrasi
  }
}
const actions = {
  Elenco: ({ commit }) => {
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        //console.log(obj)
        const Id = doc.key ? doc.key : ""
        const Codice = obj.Codice
        const CategoriaId = obj.CategoriaId
        const Frase = obj.Frase
        const Posizione = obj.Posizione
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const Payload: TypeElemento = {
          Id,
          Codice,
          CategoriaId,
          Frase,
          Posizione,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    /* eslint-disable */
    //console.log('Vuex Assistenti action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item     
    const Payload = {
      Codice: obj.Codice,
      CategoriaId: obj.CategoriaId,
      Frase: obj.Frase,
      Posizione: obj.Posizione
    } 
//console.log(Payload)     
    push(TabellaRef, Payload).then((response: { key: any }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {        
        commit("Aggiungi", { Id, ...response.val() })
      })
    })    
  },
  Aggiorna({ commit }, Elemento: { key: string; item: TypeElemento }) {
    //console.log('Vuex Assistenti action Aggiorna')
    const obj = Elemento.item
    const Id = Elemento.item.Id
    let Payload = {
      Codice: obj.Codice,
      CategoriaId: obj.CategoriaId,
      Frase: obj.Frase,
      Posizione: obj.Posizione
    }    
    update(child(TabellaRef, Id), Payload).then(() => {
      get(child(TabellaRef, Id)).then(() => {
        commit //commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    //console.log('Vuex CalendarioFilosofico action Elimina')
    const Id = Elemento.item.Id
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id)
    })
  }
}
const mutations = {
  Elenco: (
    state: { HandiphoneFrasi: TypeCollezione },
    HandiphoneFrasi: TypeCollezione
  ) => {
    state.HandiphoneFrasi =  HandiphoneFrasi
  },
  
  Aggiungi: (state: { HandiphoneFrasi: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex HandiphoneCodiciFrasi mutation Aggiungi 
    console.log(Elemento)
    const len = state.HandiphoneFrasi.length - 1
    state.HandiphoneFrasi[len].Id = Elemento.Id
    state.HandiphoneFrasi[len].Codice = Elemento.Codice
    state.HandiphoneFrasi[len].Frase = Elemento.Frase
    state.HandiphoneFrasi[len].Posizione = Elemento.Posizione
    state.HandiphoneFrasi[len].StaPerEssereInserita = false
    state.HandiphoneFrasi[len]._rowVariant = ""
    state.HandiphoneFrasi.push(ElementoVuoto())
  },
  Elimina: (state: { HandiphoneFrasi: TypeCollezione }, Id: string) => {
    //console.log('Vuex CalendarioFilosofico mutation Elimina')
    const index = state.HandiphoneFrasi.findIndex(
      (item: TypeElemento) => item.Id === Id
    )
    state.HandiphoneFrasi.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
