
import { defineComponent } from "vue"

export default defineComponent({
  name: "ComponentMenu",
  data() {
    return {
      Handiphone: {
        // eslint-disable-next-line no-undef
        backgroundImage: `url(${require("@/assets/icone_menu/handiphone.png")})`
      },
      Playlist: {
        // eslint-disable-next-line no-undef
        backgroundImage: `url(${require("@/assets/icone_menu/playlist.png")})`
      },
      RouteLink: "",
      Token: "" as string | null
    }
  },
  mounted() {
    // Menu homepage
    if (localStorage.getItem("Token")) {
      this.Token = localStorage.getItem("Token")
    }
  },
  methods: {},
  computed: {
    Url() {
      return this.$route || 1
    },
    Route() {
      return (link: string) => {
        if (this.RouteLink == link) {
          return "icone-cerchio icone-cerchio-attivo"
        } else {
          return "icone-cerchio"
        }
      }
    }
  },
  watch: {
    Url() {
      //console.log(this.Url.path.split("/")[1])
      this.RouteLink = this.Url.path.split("/")[1]
    }
  }
})
