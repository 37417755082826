import Utility from "@/helpers/vuex"

import db from "../../Conn"
import { ref, get, push, remove, child, update } from "firebase/database"

function ElementoVuoto() {
  return {
    Id: "0",
    Data: "",
    Accrediti: "",
    Addebiti: "",
    Descrizione: "",
    DescrizioneEstesa: "",
    IdCategoria: "",
    StaPerEssereInserita: false,
    StaPerEssereModificata: false,
    StaPerEssereEliminata: false,
    StaPerEssereCambiataCategioria: false,
    _rowVariant: ""
  }
}

type TypeElemento = {
  Id: string
  Data: string
  Accrediti: string
  Addebiti: string
  Descrizione: string
  DescrizioneEstesa: string
  IdCategoria: string
  StaPerEssereInserita: boolean
  StaPerEssereModificata: boolean
  StaPerEssereEliminata: boolean
  StaPerEssereCambiataCategioria: boolean
  _rowVariant: string
}

type TypeCollezione = Array<TypeElemento>

const TabellaRef = ref(db, "movimenti")

const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    //console.log("Vuex Movimenti getter Elenco")
    return state.Collezione
  }
}
const actions = {
  Elenco: ({ commit }) => {
    //console.log("Action elenco")
    //console.log("Vuex Movimenti action Elenco")
    const snapshot = get(TabellaRef)
    snapshot.then((res) => {
      const Collezione = [] as TypeCollezione
      res.forEach((doc) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Data = Utility.Data_ggmmaaaaa_aaaammgg(obj.Data)
        const Accrediti = obj.Accrediti
        const Addebiti = obj.Addebiti
        const Descrizione = obj.Descrizione
        const DescrizioneEstesa = obj.DescrizioneEstesa
        const IdCategoria = obj.IdCategoria
        const StaPerEssereInserita = false
        const StaPerEssereModificata = false
        const StaPerEssereEliminata = false
        const StaPerEssereCambiataCategioria = false
        const Payload: TypeElemento = {
          Id,
          Data,
          Accrediti,
          Addebiti,
          Descrizione,
          DescrizioneEstesa,
          IdCategoria,
          StaPerEssereInserita,
          StaPerEssereModificata,
          StaPerEssereEliminata,
          StaPerEssereCambiataCategioria,
          _rowVariant: ""
        }
        Collezione.push(Payload)
      })
      Collezione.push(ElementoVuoto())
      commit("Elenco", Collezione)
    })
  },

  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    /* eslint-disable */
    //console.log('Vuex Movimenti action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento));
    const obj: TypeElemento = data.item;
    //console.log(obj)
    const Payload = {
      Data: Utility.Data_aaaammgg_ggmmaaaa(obj.Data),
      Accrediti: obj.Accrediti,
      Addebiti: obj.Addebiti,
      Descrizione: obj.Descrizione,
      DescrizioneEstesa: obj.DescrizioneEstesa,
      IdCategoria: obj.IdCategoria,
    }
    console.log(Payload)
        push(TabellaRef, Payload).then((response: { key: any }) => {
          console.log(response)
      const snapshot = get(child(TabellaRef, response.key));
      const Id = response.key;
      snapshot.then((response) => {
        console.log(response)
        commit("Aggiungi", { Id, ...response.val() });
      })
    })
  },
  Aggiorna(
    { commit },
    Elemento: {
      key: string;
      item: TypeElemento;
    }
  ) {
    console.log('Vuex Movimenti action Aggiorna')
    //console.log(Elemento);

    const obj = Elemento.item;
    const Id = obj.Id;
    console.log(obj, Id)
    const Payload = {
      Data: Utility.Data_aaaammgg_ggmmaaaa(obj.Data),
      Accrediti: obj.Accrediti ? obj.Accrediti : 0,
      Addebiti: obj.Addebiti ? obj.Addebiti : 0,
      Descrizione: obj.Descrizione,
      DescrizioneEstesa: obj.DescrizioneEstesa,
      IdCategoria: obj.IdCategoria,
    }
    console.log(Payload)
    update(child(TabellaRef, Id), Payload).then(() => {
      get(child(TabellaRef, Id)).then((response) => {
        //commit("Aggiorna", { ...response.val(), Id: response.key });
      })
    })
  },
  Elimina(
    { commit },
    Elemento: {
      item: TypeElemento;
    }
  ) {
    //console.log('Vuex Movimenti action Elimina')
    const Id = Elemento.item.Id;
    remove(child(TabellaRef, Id)).then(() => {
      commit("Elimina", Id);
    });
  },
};

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    //console.log("mutation")
    //console.log(state.Collezione)
    //console.log(Collezione)
    //console.log('Vuex Movimenti mutation Elenco')
    return (state.Collezione = Collezione);
  },

  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex Movimenti mutation Aggiungi')
    const len = state.Collezione.length - 1;
    state.Collezione[len].Id = Elemento.Id;
    state.Collezione[len].Data = Utility.Data_ggmmaaaaa_aaaammgg(Elemento.Data);
    state.Collezione[len].Accrediti = Elemento.Accrediti;
    state.Collezione[len].Addebiti = Elemento.Addebiti;
    state.Collezione[len].Descrizione = Elemento.DescrizioneEstesa;
    state.Collezione[len].IdCategoria = Elemento.IdCategoria;
    state.Collezione[len]._rowVariant = "";
    state.Collezione.push(ElementoVuoto());
  },
  //Aggiorna: (state, Elemento) => {},
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    //console.log('Vuex CalendarioFilosofico mutation Elimina')
    const index = state.Collezione.findIndex(
      (item: TypeElemento) => item.Id === Id
    );
    state.Collezione.splice(index, 1);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
function commit(arg0: string, arg1: any) {
  throw new Error("Function not implemented.");
}
