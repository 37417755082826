const Utility = {
  Data_ggmmaaaaa_aaaammgg(obj: string) {
    let tmp = ""
    if (obj) {
      const gg = obj.substr(0, 2)
      const mm = obj.substr(3, 2)
      const aaaa = obj.substr(6, 4)
      const data_aaaa_mm_dd = aaaa + "-" + mm + "-" + gg
      tmp = data_aaaa_mm_dd
    }
    return tmp
  },

  Data_aaaammgg_ggmmaaaa(obj: string) {
    const gg = obj.substr(8, 2)
    const mm = obj.substr(5, 2)
    const aaaa = obj.substr(0, 4)
    return gg + "-" + mm + "-" + aaaa
  },

  NumeroMeseANomeMese(NumeroMese: number) {
    let NomeMese
    switch (NumeroMese) {
      case 1:
        NomeMese = "gennaio"
        break
      case 2:
        NomeMese = "febbraio"
        break
      case 3:
        NomeMese = "marzo"
        break
      case 4:
        NomeMese = "aprile"
        break
      case 5:
        NomeMese = "maggio"
        break
      case 6:
        NomeMese = "giugno"
        break
      case 7:
        NomeMese = "luglio"
        break
      case 8:
        NomeMese = "agosto"
        break
      case 9:
        NomeMese = "settembre"
        break
      case 10:
        NomeMese = "ottobre"
        break
      case 11:
        NomeMese = "novembre"
        break
      case 12:
        NomeMese = "dicembre"
        break
    }
    return NomeMese
  }
}

export default Utility
