import { ref, get, push, child, DataSnapshot } from "firebase/database"
import db from "../Conn"
const TabellaRef = ref(db, "landingpage")
type TypeElemento = {
  Id: string
  Email: string
}
type TypeCollezione = Array<TypeElemento>
const state = {
  Landingpage: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Landingpage: TypeCollezione }) => {
    return state.Landingpage
  }
}
const actions = {
  Elenco: ({ commit }) => {
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      const landingpage = [] as TypeCollezione
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        //console.log(obj)
        const Id = doc.key ? doc.key : ""
        const Email = obj.Email
        landingpage.push({ Id, Email })
      })
      commit("Elenco", landingpage)
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    /* eslint-disable */
    //console.log('Vuex Assistenti action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item     
    const Payload = {
      Email: obj.Email,
    } 
//console.log(Payload)     
    push(TabellaRef, Payload).then((response: { key: any }) => {
      const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      snapshot.then((response) => {        
        commit("Aggiungi", { Id, ...response.val() })
      })
    })    
  },
}
const mutations = {
  Elenco: (
    state: { Landingpage: TypeCollezione },
    landingpage: TypeCollezione
  ) => {
    state.Landingpage = landingpage
  },
  
  Aggiungi: (state: { Collezione: TypeCollezione }, Elemento: TypeElemento) => {
    //console.log('Vuex Assistenti mutation Aggiungi')
    console.log("Q")    
    //state.Collezione.push(ElementoVuoto())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
