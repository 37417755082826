import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"

const state = {
  _Nome: "",
  _Token: ""
}

const getters = {
  Token: (state) => {
    return state._Token
  }
}

const actions = {
  Login({ commit }) {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((response) => {
        response.user.getIdToken().then((token) => {
          commit("Login", token)
        })
      })
      .catch(() => {
        //console.log("non autorizzato")
      })
  }
}

const mutations = {
  Login: (state, token) => {
    state._Token = token
    console.log(token)

    localStorage.setItem("Token", token)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
